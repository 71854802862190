<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex align-start"
      :class="{'py-0' : small}"
    >
      <v-text-field
        outlined
        v-model="query"
        placeholder="Wyszukaj gminy"
        validate-on-blur
        :hide-details="small"
        @input="fetchCommunes"
      >
        <template #prepend-inner>
          <Icon
            name="search"
            size="small"
            class="mr-2"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        v-model="chosenCommune"
        single-select
        item-key="communeBdoId"
        class="results-table"
        :style="`min-height: ${small ? '200' : '260'}px`"
        :headers="headers"
        :items="fetchedCommunes"
        :loading="isProcessing"
        :items-per-page="-1"
        hide-default-footer
        fixed-header
        :dense="small"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            color="primary"
            class="mx-2"
            outlined
            x-small
            icon
            :disabled="!item.addable"
            :loading="isProcessing"
            @click="chosenCommune = [item]"
          >
            <v-icon color="primary">
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-expand-transition>
      <v-col
        cols="12"
        class="py-0"
        v-if="chosenCommune.length"
      >
        <v-form @submit.prevent="addCommune">
          <v-row :class="{'mt-0': small}">
            <v-col :cols="small ? 7 : 9">
              <v-autocomplete
                outlined
                hide-details
                clearable
                :items="businessPlaces"
                item-text="name"
                item-value="id"
                placeholder="Wybierz MPD"
                v-model="businessPlaceId"
                append-icon="mdi-chevron-down"
                dense
              />
            </v-col>
            <v-col :cols="small ? 5 : 3">
              <v-btn
                color="primary"
                block
                dark
                type="submit"
              >
                <Icon
                  name="add"
                  size="big"
                  color="#37C87A"
                  class="mr-4"
                />
                Dodaj gminę
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../../api/v1'
import debounce from 'lodash/debounce'

export default {
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isProcessing: false,
      fetchedCommunes: [],
      chosenCommune: [],
      businessPlaceId: undefined,
      query: '',
    }
  },
  computed: {
    ...mapState({
      businessPlaces: state => state.businessPlaces.items
    }),
    headers () {
      const headers = [
        { text: 'Gmina', value: 'name' },
        { text: 'Powiat', value: 'district' },
        { text: 'Województwo', value: 'voivodeship' },
        { text: 'Typ', value: 'communeType' },
        { text: '', value: 'actions' }
      ]
      if (this.small) headers.splice(1, 1)

      return headers
    }
  },
  mounted () {
    this.getBusinessPlaces()
  },
  methods: {
    ...mapActions({
      createCommune: 'communes/addNewItem',
      showSnackbar: 'snackbar/showSnackbar',
      getComunnes: 'communes/getItems',
      getBusinessPlaces: 'businessPlaces/getItems',
    }),
    clearChosenCommune () {
      this.chosenCommune = []
    },
    fetchCommunes: debounce(function () {
      if (this.query) {
        this.isProcessing = true
        api.fetchCommunes({ query: this.query })
          .then((resp) => {
            this.fetchedCommunes = resp.data
            this.clearChosenCommune()
          })
          .catch((error) => {
            this.showSnackbar({
              message: error.response.data.errors,
              type: 'error'
            })
          })
          .finally(() => {
            this.isProcessing = false
          })
      } else {
        this.fetchedCommunes = []
        this.clearChosenCommune()
      }
    }, 500),
    addCommune () {
      this.isProcessing = true
      const params = {
        ...this.chosenCommune[0],
        businessPlaceId: this.businessPlaceId
      }
      this.createCommune({ params })
        .then((resp) => {
          const communeIndex = this.fetchedCommunes.findIndex(commune => {
            return commune.communeBdoId === this.chosenCommune[0].communeBdoId
          })
          this.fetchedCommunes[communeIndex].addable = false
          this.clearChosenCommune()
          this.getComunnes()
          this.$emit('communeAdded', resp.id)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}
</script>
